import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

// No message specified.
extend("email", { ...email, message: "email" });

// Override the default message.
extend("required", {
  ...required,
  message: "required",
});

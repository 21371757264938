<template>
  <div :class="{ 'is-fixed': isOpenSelectCity }">
    <app-menu />
    <cities-list />
    <app-header :fixed="isFixed" />
    <div class="default-wrapper" style="min-height: 50vh">
      <slot></slot>
    </div>
    <app-footer />
    <!-- Header BURGER MENU -->
    <burger-menu></burger-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes as actionTypesCity } from "@/store/modules/city";

export default {
  name: "DefaultLayout",
  components: {
    AppMenu: () => import("@/components/common/AppMenu.vue"),
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    BurgerMenu: () => import("@/components/common/header/BurgerMenu.vue"),
    CitiesList: () => import("@/components/common/CitiesList.vue"),
  },
  data() {
    return {
      isFixed: false,
    };
  },
  computed: {
    ...mapState(["isOpenSelectCity"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
  },
  mounted() {
    if (localStorage.getItem("selectedCity")) {
      this.changeSelectedCity(JSON.parse(localStorage.getItem("selectedCity")));
    }
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    }
    this.$router.onReady(() => {
      console.log(
        "On READY",
        this.$route,
        this.$route.params.lang,
        this.$i18n.locale
      );

      if (this.$route.params.lang !== this.$i18n.locale) {
        console.log("SISS", this.$route);
        this.$router.replace({
          params: { lang: this.$i18n.locale || "ru" },
          query: { city_id: this.selectedCity?.id || 2 },
        });
      }
    });
  },
  methods: {
    ...mapActions("cityModule", {
      changeSelectedCity: actionTypesCity.changeSelectedCity,
    }),
    changeIsFixed() {
      this.isFixed = true;
    },
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/style.scss";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import "@/plugins/vue-validate";
import i18n from "./plugins/i18n";
import VueMask from "v-mask";
import LocaleRouterLink from "@/components/language/LocaleRouterLink.vue";
import helpers from "@/helpers";

Vue.component("LocaleRouterLink", LocaleRouterLink);
Vue.component("default-layout", DefaultLayout);
const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
Vue.use(plugin);
Vue.config.productionTip = false;
Vue.use(VueMask);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export const mutationTypes = {
  setSocial: "mutation/setSocial",
};
export const actionTypes = {
  getSocial: "action/getSocial",
};

export const socialModule = {
  state: () => ({
    social: null,
  }),

  mutations: {
    [mutationTypes.setSocial](state, payload) {
      state.social = payload;
    },
  },
  actions: {
    [actionTypes.getSocial]({ commit }, social) {
      commit(mutationTypes.setSocial, social);
    },
  },
  gettters: {},
};

<template>
  <div class="home">
    <homeHero />
    <homeSuccess />
    <homeAdvantages />
    <homeStores />
    <homeCities />
    <homeBanner />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    HomeHero: () => import("@/components/pages/home/HomeHero.vue"),
    HomeSuccess: () => import("@/components/pages/home/HomeSuccess.vue"),
    HomeAdvantages: () => import("@/components/pages/home/HomeAdvantages.vue"),
    HomeStores: () => import("@/components/pages/home/HomeStores.vue"),
    HomeCities: () => import("@/components/pages/home/HomeCities.vue"),
    HomeBanner: () => import("@/components/pages/home/HomeBanner.vue"),
  },
};
</script>

<template>
  <router-link
    :to="{ path: getTo(), query: { city_id: $route.query.city_id } }"
    :tag="tag"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      require: true,
    },
    tag: {
      type: String,
      default: "a",
    },
  },
  methods: {
    getTo() {
      const locale = this.$i18n.locale;

      if (typeof this.to !== "string") {
        // this.to.path = `${locale}/${this.to.path}`;
        return this.to;
      }
      return `/${locale}/${this.to.replace(/^\/|\/$/g, "")}`;
    },
  },
};
</script>

import axios from "@/plugins/axios";
import qs from "qs";

export const getAllCities = async (sortType = "asc") => {
  const query = qs.stringify(
    {
      sort: [`name:${sortType}`],
      populate: {
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/cities?${query}`);
  return response?.data?.data;
};

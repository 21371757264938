import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang(ru|kz)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "advertisers",
        name: "Advertisers",
        component: () => import("@/views/Advertisers.vue"),
      },
      {
        path: "magnum-opt",
        name: "Opt",
        component: () => import("@/views/Opt.vue"),
      },
      {
        path: "bidding",
        name: "Bidding",
        component: () => import("@/views/Bidding.vue"),
      },
      {
        path: "tenants",
        name: "Tenants",
        component: () => import("@/views/Tenants.vue"),
      },
      {
        path: "tenant-questionnaire",
        name: "TenantQuestionnaire",
        component: () => import("@/views/TenantQuestionnaire.vue"),
      },
      {
        path: "landlords",
        name: "Landlords",
        component: () => import("@/views/Landlords.vue"),
      },
      {
        path: "suppliers",
        name: "Suppliers",
        component: () => import("@/views/Suppliers.vue"),
      },
      {
        path: "supplier-questionnaire",
        name: "SupplierQuestionnaire",
        component: () => import("@/views/SupplierQuestionnaire.vue"),
      },
      {
        path: "compliance-service",
        name: "ComplianceService",
        component: () => import("@/views/ComplianceService.vue"),
      },
      {
        path: "search",
        name: "Search",
        component: () =>
          import("../views/Search.vue"),
      },
    ],
  },

  { path: "*", component: () => import("@/views/404") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  console.log(
    "LANG IN ROUTE",
    language,
    "FROM LANG",
    from.params.lang,
    "TO LANG",
    to.params.lang
  );
  if (!language) {
    language = "ru";
    to.params.lang = language;
    console.log("NOT LANG", to);
  }

  i18n.locale = language;
  next();
});

export default router;
